import React from "react";

function BoxSecondary(props) {

    return (
        <div className="box">
            <div className="header">
                <h3>{props.header}</h3>
            </div>
            <div className="content">
                {props.content}
            </div>
        </div>
    );
}

export default BoxSecondary;