import React from "react";

function Project(props) {

    return (
        <div className="project">
            <div className="image" style={{backgroundImage: `url(${props.image})`}}></div>
            <div className="hover">
                <h5>{props.header}</h5>
                <p>{props.description}</p>
                <div className="buttons">
                    <a className="button empty" href={props.demo} target="_blank" rel="noreferrer">Live Demo</a>
                    <a className="button" href={props.source} target="_blank" rel="noreferrer">Source code</a>
                </div>
            </div>
        </div>
    );
}

export default Project;