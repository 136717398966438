import React from "react";

import Box from "./components/Box";
import BoxSecondary from "./components/BoxSecondary";

import gauge from "../../assets/icons/speedometer.svg";
import bulb from "../../assets/icons/bulb.svg";
import desktop from "../../assets/icons/desktop-mobile.svg";
import rocket from "../../assets/icons/rocket-launch-lines.svg";

function About() {
    
    const fastBoxContent = {
        header: "Fast",
        text: "Fast load times and lag free." 
    }

    const responsiveBoxContent = {
        header: "Responsive",
        text: "The layouts that will work on any device." 
    }

    const userBoxContent = {
        header: "User-Friendly",
        text: "Easy to use, intuitive UX/UI." 
    }

    const dynamicBoxContent = {
        header: "Dynamic",
        text: "Animated websites. Pages don't need to be static." 
    }

    const aboutMeBoxContent = {
        header: "Get to know more about me",
        content: <>
                 <div className="cartoon-image"></div>
                 <p>I'm a Front-End Developer with interest for designing web and mobile interfaces. I'm focused on creating user-friendly experiences.</p>
                 </>
    }

    const stuffBoxContent = {
        header: "Stuff I use for my work",
        content: (
            <ul>
                <li>
                    <h5>HTML</h5>
                </li>
                <li>
                    <h5>CSS</h5>
                </li>
                <li>
                    <h5>Sass</h5>
                </li>
                <li>
                    <h5>JavaScript</h5>
                </li>
                <li>
                    <h5>React</h5>
                </li>
                <li>
                    <h5>Adobe XD</h5>
                </li>
            </ul>
        )
    }

    return (
        <section id="about" className="wrapper">
            <div className="container">
                <h1>About</h1>
                <div className="boxes">
                    <Box header={fastBoxContent.header} text={fastBoxContent.text} icon={gauge} />
                    <Box header={responsiveBoxContent.header} text={responsiveBoxContent.text} icon={desktop} position="right" />
                    <Box header={userBoxContent.header} text={userBoxContent.text} icon={bulb} />
                    <Box header={dynamicBoxContent.header} text={dynamicBoxContent.text} icon={rocket} position="right" />
                </div>
                <div className="about-box">
                    <BoxSecondary header={aboutMeBoxContent.header} content={aboutMeBoxContent.content} />
                    <BoxSecondary header={stuffBoxContent.header} content={stuffBoxContent.content} />
                </div>
            </div>
        </section>
    );
}

export default About;