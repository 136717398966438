import React, { useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";

function Navigation() {

    // Section Y positions
    const sectionPositionY = (section) => {
        switch(section) {
            case "welcome":
               return document.querySelector("#welcome").getBoundingClientRect().top + window.screenY - document.body.getBoundingClientRect().top;
            case "about":
                return  document.querySelector("#about").getBoundingClientRect().top + window.screenY - document.body.getBoundingClientRect().top;
            case "portfolio":
                return  document.querySelector("#portfolio").getBoundingClientRect().top + window.screenY - document.body.getBoundingClientRect().top;
            case "contact":
                return document.querySelector("#contact").getBoundingClientRect().top + window.screenY - document.body.getBoundingClientRect().top;
            default:
                return window.screenY; 
        } 
    }

    useEffect(() => {
        const navigation = document.getElementById("navigation");
        const about = document.getElementById("about");
        const sticky = about.getBoundingClientRect().top - document.body.getBoundingClientRect().top;

        const scrollCallBack = window.addEventListener("scroll", () => {
            if(window.pageYOffset+200 > sticky)
                navigation.classList.add("sticky");
            else
                navigation.classList.remove("sticky");
        });

        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        }
    }, [])

    return (
        <div id="navigation">
            <div className="container">
                <div className="list">
                    <ul>
                        <li><a href="#welcome" onClick={() => scroll.scrollTo(sectionPositionY("welcome"))}>Home</a></li>
                        <li><a href="#about" onClick={() => scroll.scrollTo(sectionPositionY("about"))}>About</a></li>
                        <li><a href="#portfolio" onClick={() => scroll.scrollTo(sectionPositionY("portfolio"))}>Portfolio</a></li>
                        <li><a href="#contact" onClick={() => scroll.scrollTo(sectionPositionY("contact"))}>Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Navigation;