import "./assets/css/main.css";

import Navigation from "./components/Navigation/Navigation";
import Welcome from "./components/Welcome/Welcome";
import About from "./components/About/About";
import Portfolio from "./components/Portfolio/Portfolio";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <>
      <Navigation />
      <Welcome />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
