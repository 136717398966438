import React from "react";

function Contact() {

    return (
        <section id="contact" className="wrapper style2">
            <div className="container">
                <h1>Contact</h1>
                <p>If you have a question or some project to work on, just send me a message</p>
                <div className="form">
                    <form name="contact" method="POST" netlify>
                        <input type="hidden" name="form-name" value="contact" />
                        <div className="field half">
                            <input type="text" name="name" placeholder="Name" />
                        </div>
                        <div className="field half">
                            <input type="email" name="email" placeholder="Email" />
                        </div>
                        <div className="field">
                            <textarea name="message" placeholder="Message"></textarea>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
                <p>Or send an e-mail directly to me</p>
                <a href="mailto:kamil.schynol0dev@gmail.com">kamil.schynol0dev@gmail.com</a>
            </div>
        </section>
    );
}

export default Contact;