import React from "react";

function Welcome() {

    return (
        <section id="welcome">
            <div className="content">
                <h1>Hello, I'm <span>Kamil Schynol</span>.</h1>
                <h1>I <span className="alt">desing</span>&<span>code</span> a good-looking websites</h1>
            </div>
        </section>
    );
}

export default Welcome;