import React from "react";

function Box(props) {

    return (
        <div className={`box ${props.position!=null ? props.position : "left"}`}>
            <div className="icon" style={{backgroundImage: `url(${props.icon})`}}></div>
            <div className="text">
                <h3>{props.header}</h3>
                <p>{props.text}</p>
            </div>
        </div>
    );
}

export default Box;