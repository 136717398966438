import React from "react";

import Project from "./components/Project";

import automatik from "../../assets/images/desktop_automatik.png";
import logistics from "../../assets/images/desktop_logistics.png";
import photographer from "../../assets/images/desktop_photographer.png";
import host from '../../assets/images/live_host.png'; 

function Portfolio() {

    return (
        <section id="portfolio" className="wrapper style1">
            <div className="container">
                <h1>Portfolio</h1>
                <div className="projects">
                    <Project image={host} 
                             header="Live Host" 
                             description="A responsive site template, can be used by web hosting providers. *** (Work in progress) ***"
                             demo="https://live-demo-hosting.netlify.app/"
                             source="https://github.com/Schynol-dev/hosting-provider-template-demo" />
                    <Project image={automatik} 
                             header="Automatik" 
                             description="A responsive site template, can be used by businesses working for automation industry"
                             demo="https://schynol-dev.github.io/automation-demo/"
                             source="https://github.com/Schynol-dev/automation-demo" />
                    <Project image={logistics} 
                             header="Logistics" 
                             description="A responsive site template, can be used by logistics companies."
                             demo="https://kamilschynol-logistics.netlify.app/"
                             source="https://github.com/Schynol-dev/logistic-template" />
                    <Project image={photographer} 
                             header="Photographer" 
                             description="A responsive site template, can be used by photographers to show their work."
                             demo="https://photographer-template.netlify.app/"
                             source="https://github.com/Schynol-dev/photographer-template" />
                </div>
            </div>
        </section>
    );
}

export default Portfolio;